export const CRYPTOCURRENCY_FORM = [
  {
    id: "bitcoin",
    symbol: "BTC",
    name: "Bitcoin",
    memo: false,
    wallet: "bc1qvqwsdv80dnzfuxpjj7kaa3w3ugacjg8na2zjdn",
    icon: require("../assets/img/cryptocurrency/btc.svg").default,
  },
  {
    id: "litecoin",
    symbol: "LTc",
    name: "Litecoin",
    memo: false,
    wallet: "",
    icon: require("../assets/img/cryptocurrency/ltc.svg").default,
  },
  {
    id: "ethereum",
    symbol: "ETH",
    name: "Ethereum",
    memo: false,
    wallet: "",
    icon: require("../assets/img/cryptocurrency/eth.svg").default,
  },
  {
    id: "ripple",
    symbol: "XRP",
    name: "Ripple",
    memo: false,
    wallet: "",
    icon: require("../assets/img/cryptocurrency/xrp.svg").default,
  },
  {
    id: "tron",
    symbol: "TRX",
    name: "TRON",
    memo: false,
    wallet: "",
    icon: require("../assets/img/cryptocurrency/trx.svg").default,
  },
  {
    id: "tethererc",
    symbol: "USDT1",
    name: "Tether ERC20",
    memo: false,
    wallet: "",
    icon: require("../assets/img/cryptocurrency/usdt.svg").default,
  },
  {
    id: "tethertrc",
    symbol: "USDT2",
    name: "Tether TRC20",
    memo: false,
    wallet: "",
    icon: require("../assets/img/cryptocurrency/usdt.svg").default,
  },
  {
    id: "tetherbep",
    symbol: "USDT3",
    name: "Tether BEP20",
    memo: false,
    wallet: "",
    icon: require("../assets/img/cryptocurrency/usdt.svg").default,
  },
  {
    id: "solana",
    symbol: "SOL",
    name: "Solana",
    memo: false,
    wallet: "",
    icon: require("../assets/img/cryptocurrency/sol.svg").default,
  },
  {
    id: "monero",
    symbol: "XMR",
    name: "Monero",
    memo: false,
    wallet: "",
    icon: require("../assets/img/cryptocurrency/xmr.svg").default,
  },
  {
    id: "polygon",
    symbol: "MATIC",
    name: "Polygon",
    memo: false,
    wallet: "",
    icon: require("../assets/img/cryptocurrency/matic.svg").default,
  },
  {
    id: "polkadot",
    symbol: "DOT",
    name: "Polkadot (DOT)",
    memo: false,
    wallet: "",
    icon: require("../assets/img/cryptocurrency/dot.svg").default,
  },
  {
    id: "binancecoin",
    symbol: "BNB",
    name: "BinanceCoin BEP20",
    memo: false,
    wallet: "",
    icon: require("../assets/img/cryptocurrency/bnb.svg").default,
  },
  {
    id: "stellar",
    symbol: "XLM",
    name: "Stellar",
    memo: false,
    wallet: "",
    icon: require("../assets/img/cryptocurrency/xlm.svg").default,
  },
  {
    id: "eos",
    symbol: "EOS",
    name: "EOS (EOS)",
    memo: false,
    wallet: "",
    icon: require("../assets/img/cryptocurrency/eos.svg").default,
  },
];

export const CRYPTOCURRENCY_TO = [
  {
    id: "bitcoin",
    symbol: "BTC",
    name: "Bitcoin",
    memo: false,
    reserve: "34.8 ",
    wallet: "bc1qvqwsdv80dnzfuxpjj7kaa3w3ugacjg8na2zjdn",
    icon: require("../assets/img/cryptocurrency/btc.svg").default,
  },
  {
    id: "litecoin",
    symbol: "LTC",
    name: "Litecoin",
    memo: false,
    wallet: "",
    reserve: "2444.55 ",
    icon: require("../assets/img/cryptocurrency/ltc.svg").default,
  },
  {
    id: "ethereum",
    symbol: "ETH",
    name: "Ethereum",
    memo: false,
    wallet: "",
    reserve: "1183.29 ",
    icon: require("../assets/img/cryptocurrency/eth.svg").default,
  },
  {
    id: "ripple",
    symbol: "XRP",
    name: "Ripple",
    memo: false,
    wallet: "",
    reserve: "172000.53",
    icon: require("../assets/img/cryptocurrency/xrp.svg").default,
  },
  {
    id: "tron",
    symbol: "TRX",
    name: "TRON",
    memo: false,
    wallet: "",
    reserve: "6023136.25",
    icon: require("../assets/img/cryptocurrency/trx.svg").default,
  },
  {
    id: "tethererc",
    symbol: "USDT1",
    name: "Tether ERC20",
    memo: false,
    wallet: "",
    reserve: "1480578.14",
    icon: require("../assets/img/cryptocurrency/usdt.svg").default,
  },
  {
    id: "tethertrc",
    symbol: "USDT2",
    name: "Tether TRC20",
    memo: false,
    wallet: "",
    reserve: "361558.72",
    icon: require("../assets/img/cryptocurrency/usdt.svg").default,
  },
  {
    id: "tetherbep",
    symbol: "USDT3",
    name: "Tether BEP20",
    memo: false,
    wallet: "",
    reserve: "1488416.23",
    icon: require("../assets/img/cryptocurrency/usdt.svg").default,
  },
  {
    id: "solana",
    symbol: "SOL",
    name: "Solana",
    memo: false,
    wallet: "",
    reserve: "100934.99",
    icon: require("../assets/img/cryptocurrency/sol.svg").default,
  },
  {
    id: "monero",
    symbol: "XMR",
    name: "Monero",
    memo: false,
    wallet: "",
    reserve: "33563.46",
    icon: require("../assets/img/cryptocurrency/xmr.svg").default,
  },
  {
    id: "polygon",
    symbol: "MATIC",
    name: "Polygon",
    memo: false,
    wallet: "",
    reserve: "1101600",
    icon: require("../assets/img/cryptocurrency/matic.svg").default,
  },
  {
    id: "polkadot",
    symbol: "DOT",
    name: "Polkadot (DOT)",
    memo: false,
    wallet: "",
    reserve: "855604",
    icon: require("../assets/img/cryptocurrency/dot.svg").default,
  },
  {
    id: "binancecoin",
    symbol: "BNB",
    name: "BinanceCoin BEP20",
    memo: false,
    wallet: "",
    reserve: "148366.37",
    icon: require("../assets/img/cryptocurrency/bnb.svg").default,
  },
  {
    id: "stellar",
    symbol: "XLM",
    name: "Stellar",
    memo: false,
    wallet: "",
    reserve: "1530000",
    icon: require("../assets/img/cryptocurrency/xlm.svg").default,
  },
  {
    id: "eos",
    symbol: "EOS",
    name: "EOS (EOS)",
    memo: false,
    wallet: "",
    reserve: "1381100",
    icon: require("../assets/img/cryptocurrency/eos.svg").default,
  },
];
